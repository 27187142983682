import { CommonLogger } from '@goatlab/js-utils'
import { BackendHook } from '../../types/router'
import { useSuggestedFriendsQuery } from './useSuggestedFriendsQuery'

export const friendships = ({
  logger,
  backendHook,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return {
    useFriendshipQuery: () => {
      return useSuggestedFriendsQuery({
        backendHook,
        logger,
      })
    },
  }
}
