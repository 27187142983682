import { CommonLogger } from '@goatlab/js-utils'
import { Account } from '../../types/Account'
import { BackendHook } from '../../types/router'
import { useGetAccountConnections } from './accountFeed/getAccountConnectionsQuery'
import { useAccountFeedQuery } from './accountFeed/useAccountFeedQuery'
import { useAdvertisingAccountFeedQuery } from './advertisingAccountFeed/useAdvertisingAccountFeedQuery'
import { useCommentOnPost } from './comment/useCommentOnPost'
import { useDeletePostComment } from './comment/useDeletePostComment'
import { useGetPostComments } from './comment/useGetPostComments'
import { useToggleCommentLike } from './comment/useToggleCommentLike'
import { useUpdateComment } from './comment/useUpdateComment'
import { useDeletePost } from './deleteById/deleteById'
import { useGetAccountsThatLikedPost } from './getAccountThatLikedPost/getAccounThatLikedPostQuery'
import { useHomeFeedQuery } from './homeFeed/useHomeFeedQuery'
import { useTogglePostLike } from './togglePostLike/togglePostLike'

export const posts = ({
  backendHook,
  logger,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return {
    useHomeFeedInfiniteQuery: () => {
      return useHomeFeedQuery({
        backendHook,
      })
    },
    useAccountFeedInfiniteQuery: (
      props: { visibleAccountId?: string } | undefined,
    ) => {
      return useAccountFeedQuery({
        backendHook,
        visibleAccountId: props?.visibleAccountId,
      })
    },
    useAdvertisingAccountFeedInfiniteQuery: (
      props:
        | {
            enabled?: boolean
          }
        | undefined,
    ) => {
      return useAdvertisingAccountFeedQuery({
        backendHook,
        enabled: props?.enabled,
      })
    },
    useTogglePostLike: (
      props:
        | {
            account: Account
            visibleAccountId?: string
          }
        | undefined,
    ) => {
      return useTogglePostLike({
        account: props?.account,
        visibleAccountId: props?.visibleAccountId,
        backendHook,
        logger,
      })
    },
    useGetAccountsThatLikedPost: (props: { postId?: string } | undefined) => {
      return useGetAccountsThatLikedPost({
        postId: props?.postId,
        backendHook,
      })
    },
    useDeletePostComment: (
      props: { accountId?: string; reset?: () => Promise<void> } | undefined,
    ) => {
      return useDeletePostComment({
        backendHook,
        logger,
        reset: props?.reset,
      })
    },
    useCommentOnPost: (
      props:
        | {
            account?: Account
            visibleAccountId?: string
            reset?: () => Promise<void>
          }
        | undefined,
    ) => {
      return useCommentOnPost({
        backendHook,
        visibleAccountId: props?.visibleAccountId,
        account: props?.account,
        reset: props?.reset,
        logger,
      })
    },
    useToggleCommentLike: (
      props:
        | {
            visibleAccountId?: string
            account?: Account
          }
        | undefined,
    ) => {
      return useToggleCommentLike({
        logger,
        backendHook,
      })
    },
    useUpdateComment: (
      props:
        | {
            reset?: () => Promise<void>
          }
        | undefined,
    ) => {
      return useUpdateComment({
        backendHook,
        reset: props?.reset,
        logger,
      })
    },
    useDeletePost: (
      props:
        | {
            visibleAccountId?: string
            beforeMutate?: (props: { postId: string }) => void
            afterMutate?: (props: { postId: string }) => void
            onSuccess?: (props: { postId: string }) => void
          }
        | undefined,
    ) => {
      return useDeletePost({
        backendHook,
        logger: logger!,
        visibleAccountId: props?.visibleAccountId,
        beforeMutate: props?.beforeMutate,
        afterMutate: props?.afterMutate,
        onSuccess: props?.onSuccess,
      })
    },
    useGetPostComments: (
      props:
        | {
            postId?: string
          }
        | undefined,
    ) => {
      return useGetPostComments({
        backendHook,
        postId: props?.postId,
      })
    },
    useGetAccountConnections: (props: { accountId?: string } | undefined) => {
      return useGetAccountConnections({
        accountId: props?.accountId,
        backendHook,
      })
    },
  }
}
