import { CommonLogger } from '@goatlab/js-utils'
import { BackendHook } from '../types/router'
import { useSocialConnection } from './account/useSocialConnection'
import { useHomePostFeed } from './useHomePostFeed'
import { UseHomePostProps } from './useHomePostFeed.model'

export const hooks = ({
  backendHook,
  logger,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return {
    useHomePostFeed: (props: UseHomePostProps) => {
      return useHomePostFeed({
        ...props,
        backendHook,
        logger,
      })
    },
    useSocialConnection: (props: {
      accountId: string
      userId: string
      enabled?: boolean
      onRefetch?: () => void
    }) => {
      return useSocialConnection({
        ...props,
        backendHook,
        logger,
      })
    },
  }
}
