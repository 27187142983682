import { defaultProfileImg } from '@components/ui/images/images'
import { parseJwt } from '@sodium/shared-frontend-services'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'
import Cookies from 'js-cookie'
import { create } from 'zustand'
import type { AccountWithAccess, UserStoreModel } from './authStore.model'

const generateRandomID: () => string = () => {
  let id = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    id += characters.charAt(randomIndex)
  }

  return id
}

export interface AuthStoreAction {
  setUserFromFirebaseAuth: (user: UserStoreModel) => void
  logOut: () => Promise<void>
  setToken: (token: string) => void
  setAccount: (profile: AccountWithAccess) => void
  setAuthRedirect: (route: string) => void
  refreshToken: () => Promise<void>
  isAuthenticated: () => boolean
  refreshAccount: () => void
}

export interface AuthContextState {
  user?: UserStoreModel
  redirect?: string
  refreshProfileToken?: string
}

export const useAuth = create<AuthContextState & AuthStoreAction>(
  (set, getState) => ({
    user: undefined,
    refreshAccount() {
      set({ refreshProfileToken: generateRandomID() })
    },
    async setUserFromFirebaseAuth(user) {
      if (user.token) {
        Cookies.set('token', user.token)
        delete user.token
      }

      set((state) => {
        // If we already pulled the profile from the backend
        // keep it in sync with the user
        if (state.user?.account) {
          user.account = state.user.account
        }

        if (
          state.user?.profilePicture &&
          user.profilePicture === defaultProfileImg
        ) {
          user.profilePicture = state.user?.profilePicture
        }

        return {
          user,
        }
      })
    },
    async logOut() {
      Cookies.remove('token')
      await firebaseAuthService.logout()
      set({ user: undefined })
    },
    setToken(token) {
      if (!token) {
        return
      }

      Cookies.set('token', token)

      set((state) => {
        const newUser = state.user
        if (newUser) {
          newUser.token = token
          return { user: newUser }
        }

        return state
      })
    },
    setAccount(account) {
      set((state) => {
        if (state.user) {
          return {
            user: {
              ...state.user,
              profilePicture:
                account.profilePicture || state.user.profilePicture,
              account,
            },
          }
        }

        return state
      })
    },
    setAuthRedirect(redirect) {
      set({ redirect })
    },
    async refreshToken() {
      const newToken = await firebaseAuthService.getNewToken()

      if (!newToken) {
        console.log('Could not refresh token')
        return
      }

      getState().setToken(newToken)
    },
    isAuthenticated() {
      const token = Cookies.get('token')
      if (!token) {
        return false
      }

      const decoded = parseJwt(token)
      const current = (Date.now() + 1) / 1000
      const isTokenExpired = decoded.exp < current

      if (isTokenExpired && firebaseAuthService.isUserLoggedIn()) {
        void getState().refreshToken()
      }

      if (isTokenExpired) {
        return false
      }

      return true
    },
  }),
)
