export const formatNumberToCounter = (count?: number | null): string => {
  if (!count) {
    return '0'
  }

  if (count >= 1000000000) {
    return (count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
  } else if (count >= 1000000) {
    return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else {
    return count.toString()
  }
}
