import { EnvironmentProd } from './prod.env'

export class EnvironmentDev extends EnvironmentProd {
  name = 'local'
  prod = false
  dev = true
  local = false
  test = false

  FIREBASE_API_KEY = 'AIzaSyB_lP3dcf998oNiPtfGjWmIQLvJkhdG1lk'
  FIREBASE_AUTH_DOMAIN = 'gealium-development.firebaseapp.com'
  FIREBASE_PROJECT_ID = 'gealium-development'
  FIREBASE_APP_ID = '1:650380898017:web:ab0a9e60ff577a495551a0'
  FIREBASE_MEASUREMENT_ID = 'G-8XQ3RLK8TY'

  BASE_HOST_URL = `https://api-dev.${this.BASE_DOMAIN}`
  BACKEND_URL = this.BASE_HOST_URL
  LINKEDIN_CLIENT_ID = '780welweyxsd0r'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = ''
  TYPESENSE_URL = `${this.BACKEND_URL}/search`
}

export const env = new EnvironmentDev()
