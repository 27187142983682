import type { ServiceAppRouter } from '@sodium/backend'
import type { CreateTRPCClient } from '@trpc/client'
import type { createTRPCReact } from '@trpc/react-query'
import { getTrpc } from '@sodium/shared-frontend-services'
import { env } from '@src/_env/env'
import { getToken } from '@src/api/http/getToken'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'
import Cookies from 'js-cookie'

export type {
  RouterOutputs,
  RouterInputs,
  ServiceAppRouter as AppRouter,
} from '@sodium/shared-frontend-services'

export const trpcClients = getTrpc({
  onInvalidTokenDetected: async () => {},
  getExistingToken: async () => {
    return getToken() || ''
  },
  getNewToken: async () => {
    return await firebaseAuthService.getNewToken()
  },
  setToken: async (token: string) => {
    Cookies.set('token', token)
  },
  isUserLoggedIn: async () => {
    const isUserLoggedIn = firebaseAuthService.isUserLoggedIn()
    return !!isUserLoggedIn
  },
  removeToken: async () => {
    return Cookies.remove('token')
  },
  baseApiUrl: env.BACKEND_URL || '',
  showLogs: false,
})

// TODO: Still have to figure out the proper way to do this, but at least it is properly typed, and type hints are so much faster!
export const backendHook: ReturnType<typeof createTRPCReact<ServiceAppRouter>> =
  trpcClients.backendHookNext as any

export const backendRequest: CreateTRPCClient<ServiceAppRouter> =
  trpcClients.backendRequest
