import * as path from 'path'
import packageJson from '../package.json'

export interface PackageInfo {
  name: string
  version: string
  description: string
}

export const pkg = packageJson
export const srcPath = path.join(__dirname, '.')
export const rootPath = path.join(srcPath, '../')

export const defaultProductImage =
  'https://i0.wp.com/www.thedreamauctions.com/wp-content/uploads/woocommerce-placeholder.png?fit=1200%2C1200&ssl=1'

export const ownDomains = [
  'gealium.com',
  'dev.gealium.com',
  'localhost',
  'www.gealium.com',
  'dev.gealium.com',
]

export const maxHomeFeedWidth = 555
