import type { RouterOutputs } from '@api/trpc'
import { useEffect, useRef, useState } from 'react'
import { backendRequest } from '@api/trpc'
import { useAuth } from '@components/auth/store/auth.store'
import { env } from '@src/_env/env'
import Cookies from 'js-cookie'

type CookieType = RouterOutputs['backend']['user']['getAssetCookie']

export const useCookie = () => {
  const { user } = useAuth()
  const [cookieUpdated, setCookieUpdated] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const cookieExpirationRef = useRef<Date | null>(null)
  const cookieRef = useRef<CookieType | null>(null)
  // eslint-disable-next-line no-undef
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const setCookie = async () => {
      const cookies = await backendRequest.backend.user.getAssetCookie.query()
      const expires = new Date(cookies.expires)

      // Set cookie using js-cookie
      Cookies.set(cookies.name, cookies.value, {
        expires, // Automatically converts to days in js-cookie
        path: cookies.path,
        secure: true,
        httpOnly: false,
        domain: `.${env.BASE_DOMAIN}`,
      })

      cookieExpirationRef.current = expires
      cookieRef.current = cookies
      setCookieUpdated(true)

      // Schedule the next cookie refresh 10 minutes before expiration
      const refreshTime =
        expires.getTime() - new Date().getTime() - 10 * 60 * 1000
      timeoutRef.current = setTimeout(setCookie, refreshTime)

      setIsReady(true) // Mark as ready after the cookie is set
    }

    if (user?.account?.email) {
      void setCookie()
    } else {
      setIsReady(true) // If no user, consider it ready as no cookie is needed
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [user?.firebaseId, user?.account?.email])

  return { cookieUpdated, cookie: cookieRef.current, isReady }
}
