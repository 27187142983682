export class EnvironmentProd {
  name = 'prod'
  prod = true
  dev = false
  local = false
  test = false

  APP_NAME = 'agrosocial'
  BASE_DOMAIN = 'agrosocial.com'

  PUSHER_APP_KEY = '12ce69c30d682adf47e4'
  PUSHER_APP_CLUSTER = 'sa1'

  SENTRY_DNS =
    'https://7c39ea6bee916daaac14482a31fc9bb0@o4505985012793344.ingest.sentry.io/4506097514250240'

  BASE_HOST_URL = `https://api.${this.BASE_DOMAIN}`
  BACKEND_URL = this.BASE_HOST_URL
  LINKEDIN_CLIENT_ID = '78wy10ljao5i5a'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = ''
  TYPESENSE_URL = `${this.BACKEND_URL}/search`

  FIREBASE_API_KEY = 'AIzaSyDmLtPkXqbfwO1B5WLsC47PBVmbZZq1wDM'
  FIREBASE_AUTH_DOMAIN = 'agrosocial.firebaseapp.com'
  FIREBASE_PROJECT_ID = 'agrosocial'
  FIREBASE_APP_ID = '1:480337793143:web:4bf7ff9c7fb5f1f2f5b672'
  FIREBASE_MEASUREMENT_ID = 'G-3VRQRGS1K5'

  IOS_APP_ID = 'com.agrosocial.prod'
  ANDROID_APP_ID = 'com.agrosocial.production'
}

export type Environment = EnvironmentProd

export const env = new EnvironmentProd()
