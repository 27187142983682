import { CommonLogger } from '@goatlab/js-utils'
import { BackendHook } from '../../types/router'
import { getSuggestedFriendsInput } from './getSuggestedFriendsInput'

export const useSuggestedFriendsQuery = ({
  backendHook,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return backendHook.backend.friendship.getSuggestedFriends.useInfiniteQuery(
    {
      limit: getSuggestedFriendsInput.limit,
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage
      },
    },
  )
}
