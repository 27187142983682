export const tailWindColors = {
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    foreground: 'hsl(var(--primary-foreground))',
    50: '#7fe9d5',
    100: '#75dfcb',
    200: '#6bd5c1',
    300: '#61cbb7',
    400: '#57c1ad',
    500: '#4db7a3',
    600: '#43ad99',
    700: '#39a38f',
    800: '#2f9985',
    900: '#258f7b',
  },
} as const
