import { CLFlag } from '@components/icons/CLFlag'
import { USFlag } from '@components/icons/USFlag'
import { sodiumConfig } from '@src/sodiumConfig'

export const siteSettings = {
  name: sodiumConfig.seo.name,
  description: sodiumConfig.seo.description,
  author: {
    name: 'Gealium',
    websiteUrl: 'https://www.goatlab.io',
    address: '',
  },
  logo: {
    url: sodiumConfig.topHeader.logo,
    alt: 'Gealium',
    href: '/',
    width: 40,
    height: 40,
  },
  defaultLanguage: 'en_US',
  currencyCode: 'USD',
  siteHeader: {
    languageMenu: [
      {
        id: 'en_us',
        label: 'English - US',
        value: 'en_us',
        icon: <USFlag width="20px" height="15px" />,
      },
      {
        id: 'es_cl',
        label: 'Español - CL',
        value: 'es_cl',
        icon: <CLFlag width="20px" height="15px" />,
      },
    ],
    pagesMenu: [
      {
        id: 1,
        path: '/about/professionals',
        label: 'for-professionals',
        description: 'for-professionals-description',
      },
      {
        id: 3,
        path: '/about/faq',
        label: 'faq',
        description: 'faq-description',
      },
      {
        id: 2,
        path: '/about/terms',
        label: 'terms-and-conditions',
        description: 'terms-and-conditions-description',
      },
      {
        id: 4,
        path: '/about/privacy',
        label: 'privacy-policy',
        description: 'privacy-policy-description',
      },
    ],
  },
}
