import dynamic from 'next/dynamic'
import { FaSeedling } from 'react-icons/fa6'

const SeedOutlineIcon = ({ size = 40, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 1000 1000"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="scale(1, -1) translate(0, -750)">
      <path d="M717 641v0q51 0 70-4 10-103-6-195-23-137-97-225-127-158-416-158h-55q-14 295 117 438 70 80 188 117 89 27 199 27z m0 84q-151 0-268-47-164-64-246-211-99-183-70-484 92-8 135-8 132 0 238 31 96 30 168 84 64 49 109 117 39 61 61 137 19 63 27 135 6 59 4 119-2 43-8 82l-4 33-41 6q-50 6-105 6z m-8-166q-117 0-203-45-74-35-125-101-39-55-63-125-17-49-23-102l-4-45q47 94 107 172 53 66 112 119 49 43 99 76 38 24 73 39z" />
    </g>
  </svg>
)

// Dynamically import useLottie to prevent SSR issues
const DynamicLottie = dynamic(
  async () => {
    const { useLottie } = await import('lottie-react')
    return (props: any) => {
      const { View } = useLottie(props)
      return <>{View}</>
    }
  },
  { ssr: false }, // Disable server-side rendering for this component
)

const AIComponent = (props: any) => (
  <DynamicLottie
    {...props}
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    animationData={require('./ANAvICON.json')}
  />
)

const AgroSocialLogo = () => {
  return (
    <DynamicLottie
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      animationData={require('./loading.json')}
      loop={false}
      style={{ width: 120, height: 110 }}
    />
  )
}

export const Config = {
  loading: (
    <svg
      className="h-5 w-5 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  ),
  hasLandingPage: false,
  isMarketPlace: false,
  colors: {
    primary: '#005d62',
    iconColor: '#005d62',
    selectedIconColor: '#003944',
  },
  keywords: {
    likes: 'Seeds',
  },
  icons: {
    likeDefault: <SeedOutlineIcon size={26} color="#005d62" />,
    likePressed: <FaSeedling size={20} color="#005d62" />,
  },
  login: {
    toRadialColor: '#003944',
    fromRadialColor: '#008075',
    loginImage: <AgroSocialLogo />,
  },
  buttons: {
    borderRadius: 50,
    textColor: '#bcf565',
    fontWeight: '600',
  },
  topHeader: {
    logo: 'https://www.agrosocial.com/favicon.ico',
    alt: 'Agrosocial',
  },
  seo: {
    name: 'Agrosocial',
    description:
      'The Social Network of Agriculture. Driving the evolution of modern agriculture',
  },
  legal: {
    privacyPolicyUrl: 'https://www.agrosocial.com/termsandprivacy',
    termsOfService: 'https://www.agrosocial.com/termsandprivacy',
  },
  ai: {
    chatbotLogo: AIComponent,
  },
} as const
