import { countries, getEmojiFlag, ICountry, TCountryCode } from 'countries-list'

export const useCountryOptions = () => {
  const options = []

  for (const countryCode in countries) {
    if (countries.hasOwnProperty(countryCode)) {
      const country = (countries as any)[countryCode] as unknown as ICountry
      const emoji = getEmojiFlag(countryCode as TCountryCode)
      options.push({ label: country.name, value: countryCode, emoji })
    }
  }
  return options
}
