import { RouterInputs } from '@src/types/router'

export type SuggestedFriendsByMutualsCursorLimit =
  RouterInputs['backend']['friendship']['getSuggestedFriends']

export const getSuggestedFriendsInput: SuggestedFriendsByMutualsCursorLimit = {
  limit: 10,
  cursor: {
    accountId: undefined,
    commonFriendsCount: undefined,
  },
}
