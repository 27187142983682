import { BackendHook } from '../../../types/router'
import { getAdvertisingAccountFeedQueryInput } from './advertisingAccountFeedQueryInput'

export const useAdvertisingAccountFeedQuery = ({
  backendHook,
  enabled = false,
}: {
  backendHook: BackendHook
  enabled?: boolean
}) => {
  const input = getAdvertisingAccountFeedQueryInput()

  return backendHook.backend.posts.advertisingAccountFeed.useInfiniteQuery(
    input,
    {
      enabled,
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    },
  )
}
