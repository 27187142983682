import { useZodFormHook } from '@goatlab/react-zod-form'
import { z } from 'zod'
import { langSchema } from '../schema/lang.schema'

export const globalSettingsSchema = z.object({
  description: z.string().optional().nullable(),
  language: z
    .lazy(() => langSchema)
    .optional()
    .nullable(),
  country: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  slug: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  profilePicture: z.string().optional().nullable(),
  quickBio: z.string().optional().nullable(),
  about: z.string().optional().nullable(),
  displayName: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  disableComments: z.string().optional().nullable(),
  disablePrivateMessages: z.string().optional().nullable(),
})

export type GlobalSettingsType = z.infer<typeof globalSettingsSchema>

export type GlobalSettingsProps = {
  defaultValues?: GlobalSettingsType
}

export const useGlobalSettingsForm = ({
  defaultValues,
}: GlobalSettingsProps) => {
  const formHook = useZodFormHook({
    schema: globalSettingsSchema,
    defaultValues: {
      firstName: defaultValues?.firstName || '',
      lastName: defaultValues?.lastName || '',
      language: defaultValues?.language || 'es_us',
      country: defaultValues?.country || '',
      profilePicture: defaultValues?.profilePicture || '',
      title: defaultValues?.title || '',
      email: defaultValues?.email || '',
      displayName: defaultValues?.displayName || '',
      about: defaultValues?.about || '',
      quickBio: defaultValues?.quickBio || '',
      slug: defaultValues?.slug || '',
      website: defaultValues?.website || '',
      description: defaultValues?.description || '',
      logo: defaultValues?.logo || '',
      disableComments: defaultValues?.disableComments || 'no',
      disablePrivateMessages: defaultValues?.disablePrivateMessages || 'no',
    },
  })

  return { formHook }
}
