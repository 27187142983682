export class EnvironmentProd {
  name = 'prod'
  prod = true
  dev = false
  local = false
  test = false

  APP_NAME = 'gealium'
  BASE_DOMAIN = 'gealium.com'

  PUSHER_APP_KEY = '5c078987fa10c0946698'
  PUSHER_APP_CLUSTER = 'mt1'

  SENTRY_DNS =
    'https://7c39ea6bee916daaac14482a31fc9bb0@o4505985012793344.ingest.sentry.io/4506097514250240'

  FIREBASE_API_KEY = 'AIzaSyDnmJJzs3cMWrRuAnzPEpplRrbF9etXFQE'
  FIREBASE_AUTH_DOMAIN = 'gealium.firebaseapp.com'
  FIREBASE_PROJECT_ID = 'gealium'
  FIREBASE_APP_ID = '1:1030629388031:web:d396dad9d947b5e8bf66ca'
  FIREBASE_MEASUREMENT_ID = 'G-J2GJ1EFJ75'

  BASE_HOST_URL = `https://api.${this.BASE_DOMAIN}`
  BACKEND_URL = this.BASE_HOST_URL
  LINKEDIN_CLIENT_ID = '780welweyxsd0r'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = ''
  TYPESENSE_URL = `${this.BACKEND_URL}/search`

  IOS_APP_ID = 'com.gealium.prod'
  ANDROID_APP_ID = 'com.gealium.prod'
}

export type Environment = EnvironmentProd

export const env = new EnvironmentProd()
