import * as React from 'react'
import { cn } from '../lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement>(null)
    const maxHeight = 250

    const adjustHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto' // Reset height to auto

        if (textareaRef.current.textLength > 0) {
          textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, maxHeight)}px` // Set height with max limit
        }
      }
    }

    React.useEffect(() => {
      adjustHeight() // Adjust height on initial render
    }, [])

    return (
      <textarea
        className={cn(
          `border-input bg-background ring-offset-background placeholder:text-muted-foreground flex max-h-[${maxHeight}px] w-full resize-none overflow-hidden border py-2 text-sm focus:outline-none focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50`,
          className,
        )}
        ref={(node) => {
          if (typeof ref === 'function') ref(node)
          else if (ref)
            (
              ref as React.MutableRefObject<HTMLTextAreaElement | null>
            ).current = node
          textareaRef.current = node
        }}
        onInput={adjustHeight} // Auto-expand on input
        rows={1} // Start with a single row
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
