import { UseSocialConnectionHook } from './useSocialConnection.model'

export const useSocialConnection = ({
  accountId,
  backendHook,
  userId,
  enabled = false,
  onRefetch,
}: UseSocialConnectionHook) => {
  const utils = backendHook.useUtils()
  const getSocialConnection =
    backendHook.backend.account.getSocialConnectionByAccountId.useQuery(
      { accountId },
      {
        enabled: enabled && !!accountId,
      },
    )

  const followAccountHook =
    backendHook.backend.friendship.requestFriendship.useMutation({
      onSettled: async () => {
        onRefetch?.()
        await getSocialConnection.refetch()
      },
    })

  const unFollowAccountHook =
    backendHook.backend.friendship.cancelFriendshipRequest.useMutation({
      onSettled: async () => {
        await Promise.all([
          onRefetch?.(),
          getSocialConnection.refetch(),
          utils.backend.friendship.getFriendshipNotificationsCount.refetch(),
          utils.backend.friendship.getFriendsCount.refetch(),
          utils.backend.friendship.getFriends.refetch({
            accountId: userId,
            perPage: 10,
            q: '',
          }),
        ])
      },
    })

  const onFollowPressed = async (accountId: string) => {
    await followAccountHook.mutateAsync({ friendId: String(accountId) })
  }

  const onUnFollowPressed = async (accountId: string) => {
    await unFollowAccountHook.mutateAsync({ friendId: String(accountId) })
  }

  return {
    getSocialConnection,
    followAccountHook,
    unFollowAccountHook,
    onFollowPressed,
    onUnFollowPressed,
  }
}
