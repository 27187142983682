import type { FC } from 'react'
import Image from 'next/image'
import { BotIcon, ThumbsUp } from 'lucide-react'

// set props to any because we don't know about others components
const AIComponent: FC<any> = BotIcon

export const Config = {
  loading: (
    <svg
      className="h-5 w-5 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  ),
  hasLandingPage: true,
  isMarketPlace: true,
  colors: {
    primary: '#4db7a3',
    iconColor: '#005d62',
    selectedIconColor: '#003944',
  },
  keywords: {
    likes: 'Likes',
  },
  icons: {
    likeDefault: <ThumbsUp className="h-6 w-6 text-gray-500" />,
    likePressed: <ThumbsUp className="h-6 w-6 text-blue-500" />,
  },
  login: {
    toRadialColor: '#4db7a3',
    fromRadialColor: '#4db7a3',
    loginImage: (
      <Image
        alt="logo"
        className="animate-fade-down"
        style={{ width: 180, height: 82 }}
        src={{
          src: 'https://assets-dev.gealium.com/gealium/Logo_Stacked_White_PNG_A3s1QJb5unh99h8c0kpYR.png',
          height: 102,
          width: 200,
        }}
      />
    ),
  },
  topHeader: {
    logo: '/assets/images/icon_green_40x40.png',
    alt: 'Gealium',
  },
  seo: {
    name: 'Gealium',
    description:
      'Open Health Marketplace. Connect to Health & Wellness experts globally',
  },
  legal: {
    privacyPolicyUrl: 'about/privacy',
    termsOfService: 'about/terms',
  },
  buttons: {
    borderRadius: 50,
    textColor: '#000000',
    fontWeight: '600',
  },
  ai: {
    chatbotLogo: AIComponent,
  },
} as const
